"use client";

import { useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import classes from "./page.module.scss";
import Footer from "../footer/footer";
import Appbar from "../appbar/page";

export default function Privacy() {
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          allVariants: {
            fontSize: 14,
            fontFamily: "inherit",
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <>
        <Appbar />
          <section id="terms&conditions" className={classes.container}>
            <h1>Privacy Policy</h1>
            <p>
              <strong>Effective Date:</strong> 1st Mar, 2023
            </p>

            <p>
              At SayData, we are committed to safeguarding your privacy and
              ensuring the security of your personal information. This Privacy
              Policy outlines how we collect, use, disclose, and protect your
              data when you use our Data Analytics Software as a Service (SaaS)
              platform and related services ("Services"). By accessing or using
              our Services, you consent to the practices described in this
              Privacy Policy.
            </p>

            <section>
              <h2>1. Information We Collect</h2>
              <p>
                We may collect the following types of information when you use
                our Services:
              </p>

              <h3>1.1. Personal Information:</h3>
              <ul>
                <li>
                  Contact information (e.g., name, email address, phone number)
                </li>
                <li>User account credentials (e.g., username, password)</li>
                <li>Payment information (e.g., credit card details)</li>
              </ul>

              <h3>1.2. Usage Information:</h3>
              <ul>
                <li>
                  Log data (e.g., IP addresses, browser type, operating system)
                </li>
                <li>
                  Usage metrics (e.g., pages visited, features used, session
                  duration)
                </li>
                <li>
                  Cookies and similar technologies information (see our Cookie
                  Policy)
                </li>
              </ul>

              <h3>1.3. Analytical Data:</h3>
              <ul>
                <li>
                  Data generated or uploaded to the platform by users for
                  analysis
                </li>
                <li>
                  Aggregated and anonymized usage data for analytics purposes
                </li>
              </ul>
            </section>

            <section>
              <h2>2. How We Use Your Information</h2>
              <p>
                We use the information we collect for various purposes,
                including but not limited to:
              </p>
              <ul>
                <li>Providing and improving our Services</li>
                <li>Personalizing your experience</li>
                <li>
                  Communicating with you about our Services, updates, and offers
                </li>
                <li>Processing payments</li>
                <li>
                  Analyzing and improving the performance and functionality of
                  our platform
                </li>
                <li>
                  Conducting research and generating insights (with data
                  anonymized and aggregated)
                </li>
                <li>Complying with legal obligations</li>
              </ul>
            </section>

            <section>
              <h2>3. How We Share Your Information</h2>
              <p>
                We do not sell, rent, or trade your personal information to
                third parties. However, we may share your information under the
                following circumstances:
              </p>
              <ul>
                <li>
                  With trusted third-party service providers who assist us in
                  delivering our Services (e.g., hosting, payment processing)
                </li>
                <li>When required by law or to respond to legal process</li>
                <li>
                  To protect the rights, privacy, safety, or property of
                  SayData, its users, and the public
                </li>
                <li>
                  In connection with a merger, acquisition, or sale of all or a
                  portion of our assets
                </li>
              </ul>
            </section>

            <section>
              <h2>4. Data Security</h2>
              <p>
                We implement reasonable security measures to protect your
                personal information from unauthorized access, loss, misuse,
                alteration, or destruction. However, no method of transmission
                over the internet or electronic storage is completely secure,
                and we cannot guarantee absolute security.
              </p>
            </section>

            <section>
              <h2>5. Your Choices</h2>
              <ul>
                <li>
                  You can access and update your account information through the
                  platform's settings.
                </li>
                <li>
                  You may opt out of receiving promotional communications from
                  us by following the instructions in those communications.
                </li>
                <li>
                  You can manage cookies and similar technologies according to
                  our Cookie Policy.
                </li>
              </ul>
            </section>

            <section>
              <h2>6. Children's Privacy</h2>
              <p>
                Our Services are not intended for individuals under the age of
                18. We do not knowingly collect personal information from
                children. If you are a parent or guardian and believe we have
                collected personal information from your child, please contact
                us.
              </p>
            </section>

            <section>
              <h2>7. Changes to this Privacy Policy</h2>
              <p>
                We may update this Privacy Policy from time to time. Any changes
                will be effective upon posting the updated policy on our
                website.
              </p>
            </section>

            <section>
              <h2>8. Contact Us</h2>
              <p>
                If you have questions, concerns, or requests regarding this
                Privacy Policy or your personal information, please contact us
                at{" "}
                <a href="mailto:contact@saydata.tech">contact@saydata.tech</a>.
              </p>
              <p>
                By using our Services, you agree to the terms outlined in this
                Privacy Policy.
              </p>
            </section>
          </section>
        <Footer />
      </>
    </ThemeProvider>
  );
}
