import React from "react";
import logo from "./logo.svg";
import classes from "./App.module.scss";
import { Lumiflex, Novatrix } from "uvcanvas";
import {
  AppBar,
  Button,
  CssBaseline,
  Divider,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import { CircleRounded } from "@mui/icons-material";
import clsx from "clsx";
import Emma from "./images/emma.png";
import Mark from "./images/mark.png";
import Cal from "./images/cal.png";
import Logo from "./images/logo_light_name.svg";
import Footer from "./components/footer/footer";
import Appbar from "./components/appbar/page";

const agents = [
  {
    title: "Emma, The Sales Rep",
    description: "Supercharge Your Outbound Sales, At Scale",
    image: Emma,
    features: [
      "Robust and enriched database of contacts to uncover valuable leads",
      "Writes & sends bespoke email sequences to 1000s of prospects",
      "Efficiently manage prospect inquiries",
      "Meetings directly in SDRs' calendars",
    ],
  },
  {
    title: "Mark, The Marketer",
    image: Mark,
    description: "Supercharge Your Growth Without Overheads",
    features: [
      "Creates and executes comprehensive multi-channel marketing campaigns",
      "Adapts using your team's insights to model your company",
      "Employs AI for ROI optimization",
      "Generate insights from campaign data",
    ],
  },
  {
    title: "Cal, The CS Rep",
    description: "Customer Support on Automation, At Scale",
    image: Cal,
    features: [
      "Seamlessly integrates with your CS stack for prospect monitoring",
      "Analyzes historical CS cases to enhance response reliability",
      "Optimizes for positive sentiment responses",
      "Resolves customer support issues efficiently",
    ],
  },
];

function ElevationScroll({ children }: any) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function App() {
  return (
    <div className={classes.appRoot}>
      {/* <CssBaseline /> */}
      {/* <ElevationScroll>
        <AppBar className={classes.appBar}>
          <Toolbar disableGutters className={classes.toolbar}>
            <img src={Logo} className={classes.logo} alt="logo" />
            <div className={classes.right}>
              <Button
                className={classes.text}
                target="_blank"
                href="https://analytics.saydata.tech"
              >
                Login
              </Button>
              <Button
                className={classes.primary}
                target="_blank"
                href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
              >
                Contact Us
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll> */}
      <Appbar />
      <div className={classes.center}>
        {/* <div className={classes.background}>
          <Novatrix />
        </div> */}
        <Button
          className={classes.tile}
          href="https://analytics.saydata.tech"
          target="_blank"
        >
          <CircleRounded />
          <div>Checkout our Excel Workflow Agent </div>
        </Button>
        <div className={classes.titleWrapper}>
          <div>Build your</div>
          <div className={classes.gradientText}>AI Workforce</div>
        </div>
        <div className={classes.subtitle}>
          <div>Creating the First Generation of AI Employees.</div>
          <div>
            Out-of-the-box AI employees that integrate seamlessly with human
            teams.
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            className={classes.primary}
            href="https://analytics.saydata.tech"
            target="_blank"
          >
            Try for free
          </Button>
          <Button
            variant="outlined"
            className={classes.secondary}
            href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
            target="_blank"
          >
            Request Demo
          </Button>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.agents} style={{ marginTop: 64 }}>
          {agents.map((agent, index) => (
            <div className={classes.agent} key={index}>
              <div className={classes.icon}>
                <img src={agent.image} alt={agent.title} width={64}></img>
              </div>
              <div className={classes.agentTitle}>{agent.title}</div>
              <div className={classes.agentDescription}>
                {agent.description}
              </div>
              <div className={classes.agentFeatures}>
                {agent.features.map((feature, indexf) => (
                  <div
                    className={classes.agentFeature}
                    key={index + "_" + indexf}
                  >
                    <CircleRounded />
                    <div className={classes.agentFeatureText}>{feature}</div>
                  </div>
                ))}
              </div>
              <Button
                className={classes.primary}
                target="_blank"
                href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
              >
                Learn More
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={clsx(classes.agents, classes.blur)}>
          {agents.map((agent, index) => (
            <div className={classes.agent} key={index}>
              <div className={classes.icon}>
                <img src={agent.image} alt={agent.title} width={64}></img>
              </div>
              <div className={classes.agentTitle}>{agent.title}</div>
              <div className={classes.agentDescription}>
                {agent.description}
              </div>
              <div className={classes.agentFeatures}>
                {agent.features.map((feature, indexf) => (
                  <div
                    className={classes.agentFeature}
                    key={index + "_" + indexf}
                  >
                    <CircleRounded />
                    <div className={classes.agentFeatureText}>{feature}</div>
                  </div>
                ))}
              </div>
              <Button className={classes.primary} disabled>
                Learn More
              </Button>
            </div>
          ))}
        </div>
        <div className={classes.comingSoon}>More Coming Soon</div>
      </div>
      <div className={classes.footer}>
        <Divider className={classes.divider}>
          <div className={classes.circular}></div>
        </Divider>
        {/* <div className={classes.footerText}>
          <div>© 2024 SayData. All rights reserved.</div>
          <div>Featherflow Technology Private Limited</div>
        </div> */}
        <Footer />
      </div>
    </div>
  );
}

export default App;
