"use client";

import { useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import classes from "./page.module.scss";
import Footer from "../footer/footer";
import Appbar from "../appbar/page";

export default function Terms() {
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          allVariants: {
            fontSize: 14,
            fontFamily: "inherit",
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <>
        <Appbar />
          <section id="terms&conditions" className={classes.container}>
            <h1>Terms and Conditions</h1>
            <p>
              <strong>Effective Date:</strong> 5th Jan, 2024
            </p>

            <p>
              Welcome to SayData! These terms and conditions outline the rules
              and regulations for the use of SayData, located at{" "}
              <a href="https://saydata.tech/">saydata.tech</a>.
            </p>

            <p>
              By accessing this website, we assume you accept these terms and
              conditions. Do not continue to use SayData if you do not agree to
              take all of the terms and conditions stated on this page.
            </p>

            <p>
              The following terminology applies to these Terms and Conditions,
              Privacy Statement, and Disclaimer Notice and all Agreements:
              "Client," "You," and "Your" refer to you, the person logging on
              this website and compliant with the Company's terms and
              conditions. "The Company," "Ourselves," "We," "Our," and "Us"
              refer to our Company. "Party," "Parties," or "Us" refer to both
              the Client and ourselves. All terms refer to the offer,
              acceptance, and consideration of payment necessary to undertake
              the process of our assistance to the Client in the most
              appropriate manner for the express purpose of meeting the Client's
              needs in respect to the provision of the Company's stated
              services, in accordance with and subject to the prevailing law of
              [Your Location]. Any use of the above terminology or other words
              in the singular, plural, capitalization, and/or he/she or they are
              taken as interchangeable and therefore as referring to the same.
            </p>

            <section>
              <h2>1. Cookies</h2>
              <p>
                We employ the use of cookies. By accessing SayData, you agree to
                use cookies in agreement with SayData's Privacy Policy.
              </p>
              <p>
                Most interactive websites use cookies to let us retrieve the
                user's details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </p>
            </section>

            <section>
              <h2>2. License</h2>
              <p>
                Unless otherwise stated, SayData and/or its licensors own the
                intellectual property rights for all material on SayData. All
                intellectual property rights are reserved. You may access this
                from SayData for your own personal use subjected to restrictions
                set in these terms and conditions.
              </p>
              <p>You must not:</p>
              <ul>
                <li>Republish material from SayData</li>
                <li>Sell, rent or sub-license material from SayData</li>
                <li>Reproduce, duplicate or copy material from SayData</li>
                <li>Redistribute content from SayData</li>
              </ul>
              <p>This Agreement shall begin on the date hereof.</p>
            </section>

            <section>
              <h2>3. Refund Policy</h2>
              <p>
                At SayData, we strive to provide a satisfactory experience with
                our services. Our refund policy is designed to be fair and
                transparent. Refunds can be initiated as per the terms outlined in the
                contract. If a plan is canceled with a notice of 30 days, a
                refund will be credited within 5-7 working days from the
                cancellation date.
              </p>
              <p>
                We believe in resolving any issues promptly and ensuring your
                satisfaction with our services. If you have any concerns or need
                assistance, please contact us.
              </p>
            </section>

            <section>
              <h2>4. Disclaimer</h2>
              <p>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties, and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>
              <ul>
                <li>
                  Limit or exclude our or your liability for death or personal
                  injury
                </li>
                <li>
                  Limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation
                </li>
                <li>
                  Limit any of our or your liabilities in any way that is not
                  permitted under applicable law
                </li>
                <li>
                  Exclude any of our or your liabilities that may not be
                  excluded under applicable law
                </li>
              </ul>
              <p>
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort, and for breach of statutory duty.
              </p>
              <p>
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
            </section>

            <section>
              <h2>6. Changes to this Terms & Conditions</h2>
              <p>
                We may update this terms & conditions from time to time. Any
                changes will be effective upon posting the updated policy on our
                website.
              </p>
            </section>

            <section>
              <h2>5. Contact Us</h2>
              <p>
                If you have questions, concerns, or requests regarding this
                Terms & Conditions, please contact us at{" "}
                <a href="mailto:contact@saydata.tech">contact@saydata.tech</a>.
              </p>
              <p>
                By using our Services, you agree to the terms and conditions
                outlined in this Terms & Conditions.
              </p>
            </section>

          </section>
        <Footer />
      </>
    </ThemeProvider>
  );
}
