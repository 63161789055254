import { Button, useTheme } from "@mui/material";
import styles from "./pricing.module.scss";
import { CheckCircleRounded } from "@mui/icons-material";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import Appbar from "../appbar/page";
import Footer from "../footer/footer";

export default function Pricing() {
  const theme = useTheme();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  const items = [
    // {
    //   title: "Standard",
    //   description:
    //     "Unlock the power of SayData's intuitive insights with our Standard Plan, perfect for individuals, startups and small businesses internal use-cases.",
    //   price: "$0",
    //   link: "https://app.saydata.tech",
    //   cta: "Try Now",
    //   points: [
    //     "Unlimited queries",
    //     "Unlimited internal dashboards",
    //     "1 user",
    //     "2 databases",
    //     "CSV exports and sharable links",
    //     "Email and Slack support",
    //   ],
    //   mostPopular: false,
    // },
    {
      title: "Growth",
      description:
        "Elevate your data integration and analysis capabilities with the Premium Plan, designed to cater to the needs of growing enterprises.",
      price: "$499",
      cta: "Choose Plan",
      link: "https://calendar.app.google/Ha83FJLswBiXTKCe6",
      points: [
        "Everything in Standard",
        "Fine-tuned industry specific LLM's for your data",
        "Private embeds, iframes and packages",
        "White-label solution with custom branding",
        "Upto 25 users",
        "Priority support with 1-day SLA",
      ],
      mostPopular: true,
    },
    {
      title: "Enterprise",
      description:
        "Experience the full potential with our Enterprise Plan, tailored to meet the demands of large organizations, with advanced features and dedicated support.",
      price: "Let's chat",
      cta: "Contact Us",
      link: "https://calendar.app.google/Ha83FJLswBiXTKCe6",
      points: [
        "Everything in Growth",
        "Custom visualizations and features",
        "Unlimited data schemas",
        "Custom on-prem deployment",
        "Unlimited users",
        "Dedicated account manager",
      ],
      mostPopular: false,
    },
  ];

  return isDesktopOrLaptop ? (
    <div className={styles.root}>
      <Appbar />
      <div
        className={
          theme.palette.mode === "light"
            ? styles.pricingLight
            : styles.pricingDark
        }
        id="pricing"
      >
        <div className={styles.title}>Pricing</div>
        <div className={styles.description}>
          Our pricing structure is characterized by its simplicity and
          transparency, ensuring that it seamlessly adjusts to accommodate the
          scale and dimensions of your company.
        </div>
        <div className={styles.tiles}>
          {items.map((tile, index) => (
            <div
              key={index}
              className={
                tile.mostPopular
                  ? clsx(styles.tile, styles.active)
                  : styles.tile
              }
            >
              {tile.mostPopular && (
                <div className={styles.popular}>Most Popular</div>
              )}
              <div className={styles.title}>{tile?.title}</div>
              <div className={styles.description}>{tile?.description}</div>
              <div className={styles.pricing}>
                {tile?.price}{" "}
                {tile.mostPopular && (
                  <div className={styles.text}>
                    <div>per month</div>
                  </div>
                )}
              </div>
              <Button
                className={styles.cta}
                variant="contained"
                size="small"
                href={tile?.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {tile?.cta}
              </Button>
              <div className={styles.points}>
                {tile?.points.map((point, index) => (
                  <div key={index} className={styles.point}>
                    <CheckCircleRounded className={styles.check} /> {point}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <div
      className={
        theme.palette.mode === "light"
          ? styles.pricingLightSmall
          : styles.pricingDarkSmall
      }
      id="pricing"
    >
      <Appbar />
      <div className={styles.title}>Pricing</div>
      <div className={styles.description}>
        Our pricing structure is characterized by its simplicity and
        transparency, ensuring that it seamlessly adjusts to accommodate the
        scale and dimensions of your company.
      </div>
      <div className={styles.tiles}>
        {items.map((tile, index) => (
          <div
            key={index}
            className={
              tile.mostPopular ? clsx(styles.tile, styles.active) : styles.tile
            }
          >
            {tile.mostPopular && (
              <div className={styles.popular}>Most Popular</div>
            )}
            <div className={styles.title}>{tile?.title}</div>
            <div className={styles.description}>{tile?.description}</div>
            <div className={styles.pricing}>
              {tile?.price}{" "}
              {tile.mostPopular && (
                <div className={styles.text}>
                  <div>per user</div>
                  <div>per month</div>
                </div>
              )}
            </div>
            <Button
              className={styles.cta}
              variant="contained"
              size="small"
              href={tile?.link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {tile?.cta}
            </Button>
            <div className={styles.points}>
              {tile?.points.map((point, index) => (
                <div key={index} className={styles.point}>
                  <CheckCircleRounded className={styles.check} /> {point}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
