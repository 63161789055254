import { Divider, useTheme } from "@mui/material";
import styles from "./footer.module.scss";
import logoNameLight from "../../assets/icons/logo_light_name.svg";
import logoNameDark from "../../assets/icons/logo_dark_name.svg";
import linkedin from "../../assets/icons/linkedIn.svg";
import linkedinDark from "../../assets/icons/linkedIn_d.svg";
import twitter from "../../assets/icons/twitter.svg";
import twitterDark from "../../assets/icons/twitter_d.svg";
import productHunt from "../../assets/icons/frame.svg";
import productHuntDark from "../../assets/icons/frame_d.svg";
import { useMediaQuery } from "react-responsive";

export default function Footer() {
  const theme = useTheme();
  const lightLogo = "https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=408110&theme=light&period=daily";
  const darkLogo = "https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=408110&theme=neutral&period=daily";
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });


  return isDesktopOrLaptop ? (
    <div
      className={
        theme.palette.mode === "light" ? styles.footerLight : styles.footerDark
      }
    >
      {/* <Divider /> */}
      <div className={styles.content}>
        <div className={styles.left}>
          <img
            src={theme.palette.mode === "light" ? logoNameLight : logoNameDark}
            alt="SayData"
            width={200}
          />
          <div className={styles.copyright}>Copyright © 2024 SayData</div>
          <div className={styles.contact}>Featherflow Technology Private Limited</div>
          <div className={styles.contact}>6th Floor, JMD MEGAPOLIS, 630 - 637, Badshahpur Sohna Rd Hwy, Sector 48, Gurugram</div>
          <div className={styles.contact}>Email: <a href="mailto:contact@saydata.tech">contact@saydata.tech</a></div>
          {/* <a href="https://www.producthunt.com/posts/saydata?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-saydata" target="_blank">
            <img src={theme.palette.mode === "light" ? lightLogo : darkLogo}
            alt="SayData - Build&#0032;truly&#0032;self&#0045;serve&#0032;customer&#0032;facing&#0032;analytics&#0032;using&#0032;AI | Product Hunt" /></a> */}
        </div>
        <div className={styles.right}>
          <div className={styles.links}>
            <div className={styles.header}>Links</div>
            {/* <a className={styles.text} href="/blogs">Blogs</a>
            <a
              className={styles.text}
              href="https://app.saydata.tech"
              target="_blank"
              rel="noreferrer noopener"
            >
              Dashboard
            </a> */}
            <a className={styles.text} href="/terms">Terms</a>
            <a className={styles.text} href="/privacy">Privacy</a>
            <a
              className={styles.text}
              href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
              target="_blank"
              rel="noreferrer noopener"
            >
              Contact Us
            </a>
          </div>
          <div className={styles.links}>
            <div className={styles.header}>Socials</div>
            <a
              className={styles.text}
              href="https://www.linkedin.com/company/say-data/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={theme.palette.mode === "light" ? linkedin : linkedinDark}
                alt="Linkedin"
              />{" "}
              Linkedin
            </a>
            <a
              className={styles.text}
              href="https://twitter.com/_saydata_"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={theme.palette.mode === "light" ? twitter : twitterDark}
                alt="Twitter"
              />{" "}
              Twitter
            </a>
            <a
              className={styles.text}
              href="https://www.producthunt.com/products/saydata"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={
                  theme.palette.mode === "light" ? productHunt : productHuntDark
                }
                alt="Product Hunt"
              />{" "}
              Product Hunt
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={
        theme.palette.mode === "light"
          ? styles.footerLightSmall
          : styles.footerDarkSmall
      }
    >
      <Divider />
      <div className={styles.content}>
        <div className={styles.right}>
          <div className={styles.links}>
            <div className={styles.header}>Links</div>
            {/* <a className={styles.text}>Pricing</a> */}
            {/* <a className={styles.text} href="/blogs">Blogs</a>
            <a
              className={styles.text}
              href="https://app.saydata.tech"
              target="_blank"
              rel="noreferrer noopener"
            >
              Dashboard
            </a> */}
            <a className={styles.text} href="/terms">Terms</a>
            <a className={styles.text} href="/privacy">Privacy</a>
            <a
              className={styles.text}
              href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
              target="_blank"
              rel="noreferrer noopener"
            >
              Contact Us
            </a>
          </div>
          <div className={styles.links}>
            <div className={styles.header}>Socials</div>
            <a
              className={styles.text}
              href="https://www.linkedin.com/company/say-data/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={theme.palette.mode === "light" ? linkedin : linkedinDark}
                alt="Linkedin"
              />{" "}
              Linkedin
            </a>
            <a
              className={styles.text}
              href="https://twitter.com/_saydata_"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={theme.palette.mode === "light" ? twitter : twitterDark}
                alt="Twitter"
              />{" "}
              Twitter
            </a>
            <a
              className={styles.text}
              href="https://www.producthunt.com/products/saydata"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={
                  theme.palette.mode === "light" ? productHunt : productHuntDark
                }
                alt="Product Hunt"
              />{" "}
              Product Hunt
            </a>
          </div>
        </div>
        <div className={styles.left}>
        {/* <a href="https://www.producthunt.com/posts/saydata?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-saydata" target="_blank">
          <img src={theme.palette.mode === "light" ? lightLogo : darkLogo}
          alt="SayData - Build&#0032;truly&#0032;self&#0045;serve&#0032;customer&#0032;facing&#0032;analytics&#0032;using&#0032;AI | Product Hunt"/></a> */}
          <img
            src={theme.palette.mode === "light" ? logoNameLight : logoNameDark}
            alt="SayData"
          />
          <div className={styles.copyright}>Copyright © 2024 SayData</div>
          <div className={styles.contact}>Featherflow Technology Private Limited</div>
          <div className={styles.contact}>6th Floor, JMD MEGAPOLIS, 630 - 637, Badshahpur Sohna Rd Hwy, Sector 48, Gurugram</div>
          <div className={styles.contact}>Email: <a href="mailto:contact@saydata.tech">contact@saydata.tech</a></div>
        </div>
      </div>
    </div>
  );
}
