import { AppBar, Button, Toolbar, useScrollTrigger } from "@mui/material";
import { cloneElement } from "react";
import Logo from "../../images/logo_light_name.svg";
import classes from "./page.module.scss";

function ElevationScroll({ children }: any) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
export default function Appbar() {
  return (
    <div className={classes.root}>
      <ElevationScroll>
        <AppBar className={classes.appBar}>
          <Toolbar disableGutters className={classes.toolbar}>
            <a href="/">
              <img src={Logo} className={classes.logo} alt="logo" />
            </a>
            {/* <div className={classes.text}>SayData</div> */}
            <div className={classes.right}>
              <Button
                className={classes.text}
                target="_blank"
                href="https://analytics.saydata.tech"
              >
                Login
              </Button>
              <Button
                className={classes.text}
                // target="_blank"
                href="/pricing"
              >
                Pricing
              </Button>
              <Button
                className={classes.primary}
                target="_blank"
                href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
              >
                Contact Us
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
}
